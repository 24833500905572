import React from 'react'
import { Link } from 'gatsby'

const milestonesContent = [
  {
    milestone: 'q1',
    year: '2021',
    lists: [
      {
        title: 'More affordable payment processing',
        description: 'We’re always working to provide better value for our clients, and new relationships with CardConnect and Payment Cloud enable us to offer better rates on payment processing to US based clients of all sizes and across a wide variety of industries - making Billsby even more valuable.',
        link: '/product/payment-gateways/'
      },
      {
        title: 'Accounting with FreeAgent and QuickBooks',
        description: 'Now it’s possible to automate the process of getting your Billsby revenue and refunds into your accounting software with our integrations with two of the most popular accounting packages around - FreeAgent and QuickBooks. These integrations are available for all Billsby customers.',
        link: '/product/integrations/accounting/'
      },
    ]
  },
  {
    milestone: 'q4',
    year: '2020',
    lists: [
      {
        title: 'New checkout and account management',
        description: 'Major improvements to checkout and account management introduced better mobile workflows, improvements on branding settings and the ability to use custom fonts to make Billsby Checkout look and feel like a truly native part of your application.',
        link: '/product/subscribers/checkout-and-payments'
      },
      {
        title: 'Billsby Pages for instant landing pages',
        description: 'Our customers told us they wanted to get started with Billsby without editing their website, so our Labs team created Billsby Pages allowing customers without a website - or without the technical know how to embed Billsby - the ability to sell subscriptions in just a few clicks.',
        link: '/labs'
      },
      {
        title: 'More than 2,000 new integrations',
        description: 'Zapier now allows you to connect Billsby to more than 2,000 other applications quickly and easily - revolutionising workflows and making it easier than ever before to keep all of your business data up-to-date and connected.',
        link: '/product/integrations/zapier'
      }
    ]
  },
  {
    milestone: 'q2',
    year: '2020',
    lists: [
      {
        title: 'Add-ons and allowances',
        description: 'Create ever more complex plans with add-ons and allowances - giving you the flexibility to create increasingly complex plans, and the power to charge for certain billing elements in arrears rather than just in advance. It’s the simplest way to increase your MRR.',
        link: '/product/creating/addons-allowances'
      },
      {
        title: 'Complex plan types',
        description: 'Now, it’s possible to sell more complex plan types in Billsby quickly and easily thanks to support for more involved plans - we can handle per unit, tiered and volume based billing instead of just flat fee allowing for the creation of more complex pricing structures.',
        link: '/product/creating/products-plans-cycles'
      },
    ]
  },
]

const RoadmapSection = () => {
  return (
    <div className="section-roadmap">
      <div className="container">
        <h2 className="title">Recently released enhancements</h2>
        <div className="roadmap-holder">
          {milestonesContent.map((item, i) => {
            return (
              <div key={i} className="milestone-wrapper">
                <div className="milestone">
                  <p>{item.milestone}</p>
                  <p>{item.year}</p>
                </div>
                {item.lists.map((list, j) => {
                  return (
                    <div key={j} className="milestone-item">
                      <p className="title-milestone">{list.title}</p>
                      <div className="description-holder">
                        <p className="description">{list.description}</p>
                        <Link
                          to={list.link}
                          className="btn btn-orange">Find out more
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default RoadmapSection
