import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FindOutMoreCTA from '../components/find-out-more-cta'
import Banner from "../components/compare-banner"
import RoadmapSection from "../components/roadmap-section"

const findOutMoreCTAContent = {
  title: "More great features around the corner",
  description: <>We’re always working on new and exciting enhancements to our customers Billsby service. Next up, we’re working on a <span>revenue recognition report</span>, enhanced support for <span> European payment security standards</span> and <span>more ways to pay</span>.</>,
  backgroundColor: '#F3F5FB',
  buttons: [
    {
      buttonText: "Sign up today",
      buttonColor: "orange",
      url: 'https://app.billsby.com/registration',
      isExternal: true
    },
    {
      buttonColor: "black",
      buttonText: "Schedule a demo",
      url: 'https://calendly.com/initial-customer-call/1st-customer-call'
    }
  ]
}

const bannerContent = {
  height: "auto",
  roadmapHero: "roadmap",
  title: "We're always working to make Billsby even better with new features",
  text: "When you sign up for a Billsby account, you can vote in and influence our future roadmap, to make sure that the Billsby of tomorrow is even better suited to your needs than the Billsby of today, and we're adding things all the time.",
}

const Roadmap = () => {
  return (
    <Layout>
      <SEO 
        title="Roadmap to recurring billing software enhancements | Billsby" 
        description="Roadmap to recurring billing software enhancements, affordable payment processing, reporting insights, and advanced billing solutions | Billsby" 
        url="https://www.billsby.com/roadmap"
      />
      <div className="roadmap">
        <Banner content={bannerContent}/>
        <RoadmapSection />
        <FindOutMoreCTA content={findOutMoreCTAContent} />
      </div>
    </Layout>
  )
}

export default Roadmap
